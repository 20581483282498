import React, { useEffect } from "react";
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import MyAccountContentHeader from "../MyAccountContentHeader/MyAccountContentHeader";
import MyAccountOrderDetailRow from "../MyAccountOrderDetailRow/MyAccountOrderDetailRow";
import MyAccountOrderNotes from "../MyAccountOrderNotes";
import "./MyAccountOrderDetails.css";
import * as translator from "../../../../utils/translator"
import SubmitButton from "../../../../components/SubmitButton/SubmitButton";
import PharmacySelection from "./PharmacySelection/PharmacySelection";
import * as http from '../../../../utils/http';
import { MainTracker } from "../../../../PixelTrackers/MainTracker";
import { ORDER_FLOWS } from "../../../../utils/consts";

const DEPRACATED_PAYMENT_METHOD_CODE = ["swift", "iban"]

const MyAccountOrderDetails = () => {
  const {setError ,myAccountData, country, setStickyNotificationDetails} = useOutletContext<any>();
  const { order_hash_id } = useParams();
  const language = myAccountData.locale.language_id
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(window.location.search);
  const action = queryParams.get('re');
  const orderData = myAccountData.myOrders.ordersData[order_hash_id!];
  const navigate = useNavigate();

  const paymentMethodWithoutIbanAndSwift = Object.fromEntries(
    Object.entries(orderData.paymentMethod).filter(
      ([key]) => !DEPRACATED_PAYMENT_METHOD_CODE.includes(key)
    )
  );

  //used for reship, refund, or cancel
  useEffect(() => {
    window.scrollTo(0, 0);
    if(action){
      MainTracker.track('custom', 'Customer initiated reship or cancel', {action})
      http.postToServer(
        'action',
        {
          action,
          hashID: order_hash_id
        },
        [http.MIDDLEWARES.CUSTOMER_ID]
      ).then((response)=>{
        queryParams.delete('re')
        window.history.replaceState(null, '', `${url.pathname}?${queryParams.toString()}`);

        setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: translator.translate(language, "my_account", `stickyNotifications/success/thank_you`),
          content: translator.translate(language, "my_account", `stickyNotifications/success/${response.data.messageCode}`).replace(":order_id", order_hash_id),
          level: "info",
          showInfoIcon: false,
          refresh: !currentDetails.refresh
        }))
      }).catch((error)=>{
        queryParams.delete('re')
        window.history.replaceState(null, '', `${url.pathname}?${queryParams.toString()}`);

        setStickyNotificationDetails((currentDetails: any) => ({
          show: true,
          title: "",
          content: translator.translate(language, "my_account", `stickyNotifications/error/actionDenied`).replace(":order_id", order_hash_id),
          level: "error",
          showInfoIcon: false,
          refresh: !currentDetails.refresh
        }));
      })
    }
  }, [])
  
  
  if(!order_hash_id || !country){
    return setError(400)
  }else if(!myAccountData.myOrders.ordersData[order_hash_id]){
    return setError(400)
  }
  const data = JSON.parse(JSON.stringify(myAccountData.myOrders.ordersData[order_hash_id]))
  const orderNotesString = data.orderType == 'prescription_only' ? 'orderNotesPrescriptionOnly' : 'orderNotes'

  delete data['prices']['treatmentFeeDiscount'] // we dont need this key in price display
  if(!data.isCannabisCardRequired){
    delete data['prices']['cannabisIdFee']
  }

  return (
    <>
      <div className="account-content">

        <MyAccountContentHeader
          title={`${data['hashID']} - ${translator.translate(language, "my_account", 'MyOrders/details/details')}`}
          description={""}
        />

        <div className="account-order-details">
          <MyAccountOrderDetailRow
          title={translator.translate(language, "my_account", 'MyOrders/details/hashID')}
          detailData={data['hashID']} 
          language = {language}
          />

          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/dateTime')}
            detailData={data['orderDate']}
            language = {language}
          />
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/details')}
            detailData={`${translator.translate(language, "my_account", `MyOrders/details/${data['orderType']}`)}
                          ${data.isCannabisCardRequired ? 
                          translator.translate(language, "my_account", 'MyOrders/details/digital_cannabis_id') : ''}`}
            language={language}
          />
            
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/treatment')}
            detailData={data.orderType == 'prescription_only' ? translator.translate(language, "my_account", 'MyOrders/CardPrescriptionFor') : '' + data['title']}
            language = {language}
            rowID="space-between-row"
            >
            {orderData.isAvailableForReorder &&
              (<SubmitButton
                id={"account-order-card-reorder"}
                className="align-left"
                placeholder={translator.translate(language, "my_account", 'MyOrders/reorder')}
                onSubmit={() => {
                  MainTracker.track("click","Click-Reorder");
                  navigate({ pathname: `/reorder/${data['hashID']}/${country}/${language}/medical_disclaimer/` });
                }}
              />)
            }
          </MyAccountOrderDetailRow>
          
          { (orderData.isExternalPharmacy && orderData.flow !== ORDER_FLOWS.CANNALEO_PRE_SELECTED) ?
            <PharmacySelection orderHashID={order_hash_id} language={language} />
            :
            null
          }

          <MyAccountOrderDetailRow
           title={translator.translate(language, "my_account", 'MyOrders/details/shippingMethod')} 
           detailData={orderData.isExternalPharmacy ? "Selbstabholung in der ausgewählten Apotheke" : data['shippingMethod']}
           language = {language}
           rowID= "space-between-row"
           >

          {data['orderShipping'].tracking_url && data.status === 'sent_to_customer'  && (
            <SubmitButton
              key="shippingMethod"
              id="detail-row-shipping-method"
              placeholder= {translator.translate(language, "my_account", `MyOrders/details/trackDelivery`)}
              onSubmit={()=>{window.open(data['orderShipping'].tracking_url)}}
            />
          )}
          </MyAccountOrderDetailRow>

          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/price')}
            detailData={data['prices']}
            language = {language}
          />
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/paymentInfo')}
            detailData={paymentMethodWithoutIbanAndSwift}
            language = {language}
            additionalData = {{...data['prices'], hashID:data['hashID'], country:country, isOnlineVD:data.isOnlineVD}}
          />
          <MyAccountOrderDetailRow
            title={translator.translate(language, "my_account", 'MyOrders/details/address')}
            detailData={data['address']}
            language = {language}
          />
          {data.orderType != 'prescription_only' &&
          <MyAccountOrderDetailRow
          title={translator.translate(language, "my_account", 'MyOrders/details/deliveryAddress')}
          detailData={data['deliveryAddress']} 
          language = {language}
          />
          }

          {country == 'fr' && data.orderType != 'prescription_only' && //legal requirement for fr
          <MyAccountOrderDetailRow
          title={translator.translate(language, "my_account", 'MyOrders/details/pharmacyDetails')}
          detailData={data['pharmacyDetails']} 
          language = {language}
          />
          }


        </div>

        <MyAccountOrderNotes
          htmlContent={translator.translate(language, "my_account", `MyOrders/details/${orderNotesString}`)}
          language={language}
          orderStatus={myAccountData.myOrders.ordersData[order_hash_id].status}
        />
      </div>
    </>
  );
};

export default MyAccountOrderDetails;
