import { useEffect, useState } from "react";
import * as fieldsValidator from "../utils/fieldsValidator";
const useForm = (initialValues: any, language?: string, destionationCountry?:string) => {

  //maps fields to values
  const [form, setForm] = useState<any>(initialValues);

  //holds the validy information per field
  const [formValidationObject, setFormValidationObject] = useState<any>({});

  //flag that states if the form is valid as a whole
  const [isFormValid, setIsFormValid] = useState<Boolean>(false);

  //maps fields to error messages (depending on the validation rule that is broken)
  const [errorMessagePerField, setErrorMessagePerField] = useState<any>({});

  // states which fields where "touched"
  const [touched, setTocuhed] = useState<any>({});

  //triggers checking if the form is valid and updating 'isFormValid'
  const validateForm = (ignoreValidationFields?: string[]) => {
    let formValidationObjectCopy = JSON.parse(JSON.stringify(formValidationObject));
    let errorMessagePerFieldCopy = JSON.parse(JSON.stringify(errorMessagePerField));
    let touchedCopy = JSON.parse(JSON.stringify(touched));

    Object.keys(form).forEach((field) => {
      if(ignoreValidationFields && ignoreValidationFields.includes(field)){

        formValidationObjectCopy[field] = true;
        touchedCopy[field] = true;

      } else {
        let { isValid, errorMsg } = validateField(field, form[field]);
        formValidationObjectCopy[field] = isValid;
        errorMessagePerFieldCopy[field] = errorMsg;
        touchedCopy[field] = true;
      }
    });

    setFormValidationObject(formValidationObjectCopy);
    setErrorMessagePerField(errorMessagePerFieldCopy);
    setTocuhed(touchedCopy);

    let isFormValid = !Object.values(formValidationObjectCopy).includes(false);
    setIsFormValid(isFormValid);
    
    return isFormValid;
  };

  //globally manage all validation rules in one function
  const validateField = (field: string, value: string) => {
    //custom validator - imported from an external module

    if (field === "repeat_password") {
      return fieldsValidator.validateRepeatPassword(value, form["password"], language);
    } else {
      return fieldsValidator.validateByField(field, value, language, destionationCountry);
    }

  };


  //used to update a value of a given field, also automatically updates the form validation object + sets the correct error message
  const updateForm = (field: string, newValue: string) => {
    let formCopy = JSON.parse(JSON.stringify(form));

    formCopy[field] = newValue;

    setForm((oldForm: any) => formCopy);

    updateFormValidationObject(field, newValue);
  };

  const updateFormMultipleAttributes = (fieldValueList: [string, string][]) => {
    let formCopy = JSON.parse(JSON.stringify(form));
    fieldValueList.forEach(([field, value]) => {
      formCopy[field] = value;
    })

    setForm((oldForm: any) => formCopy);

    updateFormValidationObjectMultiple(fieldValueList);
  };

  const updateFormValidationObjectMultiple = (fieldValueList: [string, string][]) => {

    const validation_object: Record<string, boolean> = {}
    const error_object: Record<string, any> = {}
    //get the isValid bool and the corresponding errorMsg (if there is one)
    fieldValueList.forEach(([field, value]) => {
      let { isValid, errorMsg } = validateField(field, value);
      validation_object[field] = isValid
      error_object[field] = errorMsg
    })
    
    setFormValidationObject({ ...formValidationObject, ...validation_object });
    setErrorMessagePerField({ ...errorMessagePerField, ...error_object });
  };


  const onFieldBlur = (field: string) => {
    if (!form[field]) {
      return;
    }

    let touchedCopy = JSON.parse(JSON.stringify(touched));
    touchedCopy[field] = true;
    setTocuhed(touchedCopy);

  }

  const updateFormValidationObject = (field: string, value: string) => {

    //get the isValid bool and the corresponding errorMsg (if there is one)
    let { isValid, errorMsg } = validateField(field, value);
    setFormValidationObject({ ...formValidationObject, [field]: isValid });
    setErrorMessagePerField({ ...errorMessagePerField, [field]: errorMsg });
  };


  const initFormAttributes = (initialValues: any) => {
    let formValidationObjectCopy: any = {};
    let errorMessagePerFieldCopy: any = {};
    let touchedCopy: any = {};


    //validate every intial value and set the proper error msg
    const formattedInitialValues: any = {};

    Object.keys(initialValues).forEach((field: any) => {
      formValidationObjectCopy[field] = false;
      errorMessagePerFieldCopy[field] = "";
      touchedCopy[field] = false;
      formattedInitialValues[field] = initialValues[field] ? initialValues[field] : "";
    });

    setFormValidationObject(formValidationObjectCopy);
    setErrorMessagePerField(errorMessagePerFieldCopy);
    setTocuhed(touchedCopy);
    setForm(formattedInitialValues);
  }

  const overrideForm = (newValues: any) => {
    initFormAttributes(newValues);
  }

  useEffect(() => {
    //this useEffect inits the whole form (values, validatiyObject, errors)
    initFormAttributes(initialValues);
  }, []);

  return {
    form,
    isFormValid,
    formValidationObject,
    updateForm,
    updateFormMultipleAttributes,
    updateFormValidationObject,
    validateForm,
    errorMessagePerField,
    overrideForm,
    touched,
    onFieldBlur
  };
};

export default useForm;
