import React from 'react';
import { useOutletContext,useNavigate } from "react-router-dom";
import "./MyAccountOrderCard.css";
import { Link } from "react-router-dom";
import * as translator from "../../../../utils/translator"
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import BadgeNotification from '../BadgeNotification/BadgeNotification';
import Significance from '../../../Questionnaire/components/Significance/Significance';
import { MainTracker } from '../../../../PixelTrackers/MainTracker';
import * as http from '../../../../utils/http';

interface MyAccountOrderCardProps {
  productImgSrc: string;
  orderTitle: string;
  orderHashId?: string;
  orderDate?: string;
  orderStatus?: string;
  orderPriceTotal?: string; 
  orderTreatmentFee?: string | number; 
  country?: string;
  orderApplicationNotes: null | string;
  isAvailableForReorder: boolean; //To show reorder button
  mention: string;
  catalogID: number;
  notificationsAmount: number;
  orderRejectReason: string;
  paymentMethod:any;
  orderShipping:any;
  isOtcOrder?: boolean;
  recipeCopy:any;
}

const MyAccountOrderCard: React.FC<MyAccountOrderCardProps> = (props) => {
  
  const navigate = useNavigate();
  const { country, language } = useOutletContext<any>();

  if(!language){
    return <></>
  }

  const getRecipeCopy = () =>{
    const attachment = props.recipeCopy
    http.postToServer(
        `download-file`,
        {
          s3Key: attachment.base_path + attachment.name,
        },
        [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.GENERATE_FILE]
      )
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data],{type:attachment.mime_type}));
        const link = document.createElement("a");
        link.className = "blob-link"
        link.href = url;
        link.setAttribute("download", attachment.display_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        // Handle error
      });
  }


  return (
    <div className="account-order-card">
      <div className="account-order-card-head">

        <div className="account-order-card-head-container">
          <div className="product-img">
            <img src={props.productImgSrc} alt="product-img" />
          </div>

          <div className="order-info">
            <p className="order-title">{props.orderTitle}</p>
            <ul>
              <li>
                <p className="order-info-field">{translator.translate(language, "my_account", 'MyOrders/cardHashID')}</p>
                <p className="order-info-field-value">{props.orderHashId}</p>
              </li>
              <li>
                <p className="order-info-field">{translator.translate(language, "my_account", 'MyOrders/cardDateTime')}</p>
                <p className="order-info-field-value">{props.orderDate}</p>
              </li>
              <li>
                <p className="order-info-field">{translator.translate(language, "my_account", 'MyOrders/cardStatus')}</p>
                <p className="order-info-field-value">{translator.translate(language, "my_account", `MyOrders/status/${props.orderStatus}`)}{ }</p>
              </li>
            </ul>
          </div>

          <div className="order-prices">
            <p className="order-price-without-treatmentfee">{props.orderPriceTotal}</p>
            {
              props.orderTreatmentFee ? <p className="order-treatmentfee">{'(' + translator.translate(language, "my_account", 'MyOrders/cardTreatmentFee') + ' ' + props.orderTreatmentFee + ')'}</p> : null
            }
          </div>
        </div>
        <div>
          {props.orderRejectReason &&
            <Significance id={"account-significance"} 
            dangerSignificancesRefsDispatch={null}
            parentID={null} showIcon level={"danger"} 
            fontWeight={700} fontSize={'15px'}
            text={translator.translate(language, "my_account", 'MyOrders/rejectReason')} 
            description={props.orderRejectReason}
            fontFamily='Roboto, sans-serif'
            />            
          }
        </div>
      </div>

      <div className="account-order-card-bottom">
        <div className="account-order-card-links">
          {
            props.isOtcOrder ?
            null
            :
            <div className="account-order-card-link">
              <Link to={`${props.orderHashId}/prescription`} >{translator.translate(language, "my_account", 'MyOrders/cardQuestionnaire')}</Link>
              <BadgeNotification amount={props.notificationsAmount} />
            </div>
            
          }
          <div className="account-order-card-link">
            <Link to={`${props.orderHashId}/details`}>{translator.translate(language, "my_account", 'MyOrders/cardDetails')}</Link>
          </div>
          {
            props.orderApplicationNotes && <Link to={`${props.orderHashId}/treatment-instructions`} className="account-order-card-link">{translator.translate(language, "my_account", 'MyOrders/cardInstructions')}</Link>
          }
          {
            props.recipeCopy && <a onClick={getRecipeCopy} className="account-order-card-link">{translator.translate(language, "my_account", 'MyOrders/recipe-copy')}</a>
          }
        </div>
           { props?.orderShipping?.tracking_url && props.orderStatus === 'sent_to_customer' && (
           <SubmitButton
            id="detail-row-track-delivery"
            height='46px'
            placeholder={translator.translate(language, "my_account", `MyOrders/details/trackDelivery`)}
            onSubmit={()=>{window.open(props.orderShipping.tracking_url)}}
          />
          )}

        { props.paymentMethod.isShowPaymentButton &&
          <SubmitButton
            id="detail-row-pay-now"
            height='46px'
            placeholder={translator.translate(language, "my_account", `MyOrders/details/payOnline`)}
            onSubmit={()=>{navigate(`/payment/${country}/${language}/${props.orderHashId?.split('-')[0]}`)}}
          />
        }

        {
          props.isAvailableForReorder &&
          //We show reorder button option only if the order is delivered
            <SubmitButton
              id={"account-order-card-reorder"}
              onSubmit={() => {
                //mp event
                MainTracker.track("click","Click-Reorder");
                navigate({ pathname: `/reorder/${props.orderHashId}/${country}/${language}/medical_disclaimer/` });
              }}
              placeholder={translator.translate(language, "my_account", 'MyOrders/reorder')}
            />
        }

      </div>
    </div>
  )
}

export default MyAccountOrderCard