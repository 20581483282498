
import React, { useContext } from 'react'
import { ChooseTreatmentContext } from '../CannaleoFreeDosageLayout'
import SearchIcon from "../../../../icons/Search-White.svg"

type Props = {
    className?: string
}

const ProductSearch:React.FC<Props> = ({className}) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);

    return (
        // Product Search Container 
        <div id='product-search-container' className={`w-full shadow-sm rounded-md items-center flex justify-between gap-4 py-3 px-4 bg-white ${className}`}>
            
            {/* Product Search Input  */}
            <input id='product-search' className='text-md h-full w-full flex-1 border-none rounded-md focus:outline-none'
                type="text"
                onChange={(e) => chooseTreatmentContext?.setSearchTerm(e.target.value)}
                placeholder='In dieser Kategorie suchen'
            />
            
            {/* Product Search Icon  */}
            <img src={SearchIcon} id='product-search-icon' className='bg-[#11DDAC] rounded-md flex justify-center p-2 text-xl text-white items-center' />
        </div>
    )
}

export default ProductSearch
