import React from 'react';
import Disclaimer from '../../components/Disclaimers/Disclaimer';
import SubmitButton from './SubmitButton';
import { useParams } from 'react-router-dom';
import ShoopingCart from '../../../../icons/Shopping-Cart.svg';

type Props = {
	disclaimerText: string;
	showDisclaimer: boolean;
	buttonDisabled: boolean;
	selectedProductsAmount: number;
	maxSelectedProductsAmount: number;
	showAmountOfSelectedProducts: boolean;
	onSubmit: () => void;
	onArrowClick?: () => void; // Fixed type
	onCartClick?: () => void; // Fixed type
};

const StickySubmitButton: React.FC<Props> = ({
	maxSelectedProductsAmount,
	disclaimerText,
	showDisclaimer,
	buttonDisabled,
	selectedProductsAmount,
	showAmountOfSelectedProducts,
	onSubmit,
	onArrowClick,
	onCartClick,
}) => {
	const { language } = useParams();

	return (
		<div
			id="sticky-submit-container"
			className="w-full flex flex-col gap-y-2 items-center justify-center sticky bottom-0 right-0"
			style={{ backgroundColor: '#f7f7f7', borderTop: '2px solid white' }}
		>
			{showDisclaimer ? (
				<div id="sticky-submit-disclaimer-container" className="w-full flex items-center justify-center">
					<Disclaimer
						text={disclaimerText}
						containerClassName={`flex max-w-[425px] p-3 items-center bg-[#EDFCF0] shadow-lg shadow-[#E5E7E5] gap-2 transition-all duration-300 ${
							showDisclaimer ? 'opacity-100 animate-fade-in-1' : 'opacity-0 pointer-events-none'
						}`}
						textClassName="!text-[#0D2C54] font-roboto text-lg !font-medium"
						iconClassName="!text-[#19D845]"
					/>
				</div>
			) : null}

			<div id="sticky-submit-container" className="w-full flex justify-center">
				<div id="sticky-submit" className="w-[500px] p-4 md:py-4 md:px-0 flex items-center gap-[15px] relative">
					{/* Arrow Up
					{Boolean(onArrowClick) && (
						<div
							id="arrow-up-container"
							onClick={onArrowClick}
							style={{ zIndex: '5', boxShadow: '0px 15px 25px #98A0AF40' }}
							className="absolute cursor-pointer hover:opacity-85 select-none left-4 md:left-0 -top-[20px] bg-white rounded-full flex items-center justify-center"
						>
							<img src={ChevronUp} alt="^" className="w-[40px] h-[40px]" />
						</div>
					)} */}

					{/* Selected products cart  */}
					{showAmountOfSelectedProducts ? (
						<div
							id="cart-container"
							className="flex flex-row justify-center items-center cursor-pointer gap-2"
							onClick={onCartClick}
							style={{
								backgroundColor: '#FCFCFC',
								width: '140px',
								height: '100%',
								borderRadius: '5px',
								backdropFilter: 'blur(4px)',
								boxShadow: '0 4px 6px #E1E3E6',
							}}
						>
							<div className="flex flex-col w-full justify-center items-center gap-[1px]">
								<div className="flex flex-row w-full justify-center items-center gap-1">
									<img src={ShoopingCart} className="w-[32px] h-[32px]" alt="Cart" />
									<div
										id="cart-data"
										className="flex flex-col justify-center items-center"
										style={{
											fontFamily: 'Roboto, sans-serif',
										}}
									>
										<p
											id="sticky-submit-cart-products-amount"
											style={{
												aspectRatio: '1', // Ensures a square shape
												minWidth: 'fit-content', // Prevents the width from being too small
												width: '32px',
												height: '16px',
												fontSize: '11px',
												marginBottom: '2px',
											}}
											className="text-nowrap bg-[#19D845] text-white rounded-full flex items-center justify-center top-[-13px] right-[-14px] tracking-tighter font-semibold"
										>
											{selectedProductsAmount} / {maxSelectedProductsAmount}
										</p>
									</div>
								</div>
								<span
									className="text-center"
									style={{
										color: 'rgba(96, 96, 96, 1)',
										lineHeight: '14px',
										fontSize: '12px',
									}}
								>
									Warenkorb
								</span>
							</div>
						</div>
					) : null}

					<div id="sticky-submit-button" className="w-full ">
						<SubmitButton languageID={language!} disabled={buttonDisabled} desktop onClick={onSubmit} className="rounded-md w-full min-w-fit" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default StickySubmitButton;
