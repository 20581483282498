import DHLPackstationShipping from "../DHLPackstationShipping/DHLPackstationShipping";
import PersonalAdderssShipping from "../PersonalAdderssShipping/PersonalAdderssShipping";
import { ShippingDataContext } from "../../Shipping";
import * as translator from "../../../../utils/translator";
import { useContext, useEffect } from "react";
import SelfPickupShipping from "../SelfPickupShipping/SelfPickupShipping";
import { useSearchParams } from "react-router-dom";
import CannaleoDeliveryPanel from "../CannaleoDeliveryPanel";
import { ORDER_FLOWS } from "../../../../utils/consts";
// const ConfigManager = require("funnel-common").ConfigManager;
//import * as ConfigManager from "../../../../../../funnel-common/src/ConfigManager/index";

//shipping constants
const PERSONAL_ADDRESS_METHOD_INDEX = 1;
const DHL_ADDRESS_METHOD_INDEX = 2;

//payment constants
const ONLINE_PAYMENT_OPTION_INDEX = 0;


const ShippingLayout = ({
  productCatalog,
  isOnlyPrescription,
  isSubscriptionFlow,
  isTreatmentOTC,
  updatePrescriptionOnly,
  selectedDeliveryMethodIndex,
  orderWindowDetails,
  updateDeliveryMethodID,
  pageState,
  onDeliveryMethodChange,
  isMethodSelected,
  setPrescriptionOnly,
  selectedDeliveryMethodID,
  selectedPaymentMethod,
  paymentImages,
  onSubmit,
  onPaymentMethodChange,
  generateOrderWindow,
  selectedPersonalShippingIndex,
  updateSelectedPersonalShippingIndex,
  children,
  codSupport,
  wireSupport,
  cartID,
}) => {


  const {
    country,
    language,
    prescriptionOnly,
    shippingMethodsByPriority,
    minimalShippingMethodPriorty,
    limitToPrescriptionOnly,
    destinationCountry,
    DBcart,
    selfPickupPharmacy,
    catalog
  } = useContext(ShippingDataContext);


  useEffect(() => {
    onPaymentMethodChange(ONLINE_PAYMENT_OPTION_INDEX);
  }, [prescriptionOnly, destinationCountry]);



  const [searchParams] = useSearchParams();

  const getShippingOptions = () => {
    if (selfPickupPharmacy) {
      return <SelfPickupShipping />;
    }
  
    if (searchParams.get("epi") || DBcart.flow === ORDER_FLOWS.CANNALEO_PRE_SELECTED) {
      return <CannaleoDeliveryPanel />;
    }
  
    return (
      <PersonalAdderssShipping
        allowPrescriptionOnly={
          !isSubscriptionFlow &&
          !isTreatmentOTC &&
          destinationCountry !== 'ch' &&
          !DBcart.is_e_recipe
        }
        onClick={() =>
          onDeliveryMethodChange(
            PERSONAL_ADDRESS_METHOD_INDEX,
            shippingMethodsByPriority[minimalShippingMethodPriorty]?.id
          )
        }
        updatePrescriptionOnly={updatePrescriptionOnly}
        pageState={pageState}
        selected={
          selectedDeliveryMethodID ===
            shippingMethodsByPriority[minimalShippingMethodPriorty]?.id &&
          isMethodSelected(selectedDeliveryMethodIndex, PERSONAL_ADDRESS_METHOD_INDEX)
        }
        params={{ price: `${DBcart.treatment_fee.toFixed(2)}€` }}
        updateDeliveryMethodID={updateDeliveryMethodID}
        selectedPersonalShippingIndex={selectedPersonalShippingIndex}
        updateSelectedPersonalShippingIndex={updateSelectedPersonalShippingIndex}
      />
    );
  };
  

  return (
    <div id="shipping-layout">
      <p className="shipping-instruction-title">
        {translator.translate(language, "shipping", "layout/shipping_title")}
      </p>


      <div className="shipping-container">

        {/* Left Column Form & options*/}
        {getShippingOptions()}

        {/* Left Column DHL Packstation */}
        {
          selfPickupPharmacy || limitToPrescriptionOnly || country.toLowerCase() === "fr" || catalog.content_type === "cannabis" ? null : (
            <DHLPackstationShipping
              disabled={
                isOnlyPrescription || productCatalog?.disable_packstation
              }
              onClick={() => {
                onDeliveryMethodChange(DHL_ADDRESS_METHOD_INDEX, shippingMethodsByPriority[minimalShippingMethodPriorty].id);
                setPrescriptionOnly(false);
                updateSelectedPersonalShippingIndex(0);
              }}
              isOnlyPrescription={isOnlyPrescription}
              selected={selectedDeliveryMethodID == shippingMethodsByPriority[minimalShippingMethodPriorty].id && isMethodSelected(selectedDeliveryMethodIndex, DHL_ADDRESS_METHOD_INDEX)}
            />
          )
        }

      </div>

      
      {/* Right Column (OrderWindow / CartView) */}
      <div className="countryLayout-orderWindow">{children}</div>


      {/* MAYBE SAVE THIS FOR THE FUTURE (Old submit button inside the payment cards, instead of the new stick submit) */}
      {/* <div className="payment-info-container"> */}
      {/* ONLINE PAYMENT  */}
      {/* <PaymentOptionCard
          icons={country.toLowerCase() == "de" ? DE_ONLINE_PAYMENT_IMAGES : FR_ONLINE_PAYMENT_IMAGES} */}
      {/* onSubmit={() =>
            onSubmit(adyenDropInMethodID, "ONLINE", selectedDeliveryMethodID)
          }
          paymentTypeID={adyenDropInMethodID} //Online payment
          title={translator.translate(
            language,
            "shipping",
            "paymentMehods/online/title"
          )}
          description={translator.translate(
            language,
            "shipping",
            "paymentMehods/online/description"
          )}
          onClick={() => onPaymentMethodChange(ONLINE_PAYMENT_OPTION_INDEX)}
          selected={isMethodSelected(
            selectedPaymentMethod,
            ONLINE_PAYMENT_OPTION_INDEX
          )}
          language={language}
          longIconList */}
      {/* /> */}

      {/* </div> */}

    </div>
  );
};

export default ShippingLayout;
