import React from "react";
import "./OrderOTCAddons.css";
import OTCProductRow from "../OTCProductRow/OTCProductRow";
import { useParams } from "react-router-dom";
import { getLanguageByCountry, translate } from "../../utils/translator";


type OrderOTCAddonsProps = {
  title: string;
  selectedOtcAddons: ProductAndQuantity[];
  currencySign: string //countryCurrencySign ($)
  currencyRate: number // the rate in the currency of the country (for example in German & Swiss changes)
  OTCAddonsAttributes?: OTCAddonsAttributes;
  showOtcAddonsModal?: () => void;
  showOtcAddonsModalButtonText?: string;
  shouldShowOtcAddonsModalButton?: boolean;
};

// This is the component that shows each OTCProductRow in shipping page (underneath OrderWindow)

const OrderOTCAddons: React.FC<OrderOTCAddonsProps> = ({
  title,
  selectedOtcAddons,
  currencySign,
  currencyRate,
  OTCAddonsAttributes,
  showOtcAddonsModal,
  showOtcAddonsModalButtonText,
  shouldShowOtcAddonsModalButton = true,
}) => {
  const { country } = useParams();

  if (!country) {
    return <> </>;
  }

  const cannabisId = selectedOtcAddons.find((productAndQuantity: ProductAndQuantity) => productAndQuantity.productItem.title === "Cannabis ID")

  if (cannabisId) {
    return <> </>;
  }

  return (
    <div className="order-otc-addons-container">
      <p className="order-otc-addons-title">{title}</p>
      <div className="otc-addons-product-rows">
        {selectedOtcAddons.map(
          (productAndQuantity: ProductAndQuantity, index: number) => {
            return (
              <OTCProductRow
                key={index}
                productItem={productAndQuantity.productItem}
                currencyRate={currencyRate}
                currencySign={currencySign}
                OTCAddonsAttributes={ OTCAddonsAttributes ? OTCAddonsAttributes : null }
                quantityProp={OTCAddonsAttributes ? undefined : productAndQuantity.quantity}
                countryId={country!}
              />
            );
          }
        )}
      </div>
      
      {/*OTC Modal trigger  */}
      {shouldShowOtcAddonsModalButton ? 
        <div className="open-otc-addons-modal-container">
          <button
            className="open-otc-addons-modal"
            onClick={() => OTCAddonsAttributes?.showModal()}
          >
            {
              translate(getLanguageByCountry(country),"default","OrderWindow/otc_modal_trigger")
            }
          </button>

        </div>
        
        : 

        null
      }
    </div>
  );
};

export default OrderOTCAddons;
