import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as http from "../../../../../utils/http";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import RadioSelect from '../../../../../components/RadioSelect/RadioSelect';
import { useOutletContext } from 'react-router-dom';
import { MainTracker } from '../../../../../PixelTrackers/MainTracker';
import { MdInfoOutline } from 'react-icons/md';
import * as translator from "../../../../../utils/translator";

type Props = {
    orderHashID: string
    language: string;
}

interface PharmacyInfo {
    name: string;
    city: string;
    email: string;
}

const PharmacySelection: React.FC<Props> = ({ orderHashID, language }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [pharmacies, setPharmacies] = useState<ExternalPharmacy[]>([]);
    const { setStickyNotificationDetails, setCurrentGlobalModalName, myAccountData } = useOutletContext<any>();
    const orderData = myAccountData.myOrders.ordersData[orderHashID]
    const [selectedPharmacyID, setSelectedPharmacyID] = useState<number | null>(orderData.selectedEPharmacyID);
    const [selectedPharmacy, setSelectedPharmacy] = useState<ExternalPharmacy | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(!Boolean(selectedPharmacyID));
    const [isPharmacySelectionApproved, setIsPharmacySelectionApproved] = useState(false);


    const filteredPharmacies = useMemo(() => {
        return pharmacies.filter((pharmacy) => pharmacy.name?.toLowerCase().includes(searchQuery.toLowerCase()) || pharmacy?.address?.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [pharmacies, searchQuery]);

    const allowMenuTrigger = useMemo(() => {
        return orderData.allowEPharmacyChange;
    }, [orderData.allowEPharmacyChange]);

    useEffect(() => {
        http.getFromServer(`account/pharmacy-selection-view/${orderHashID}`, [http.MIDDLEWARES.CUSTOMER_ID])
            .then((res: any) => {
                const pharmacySelectionApproved = Boolean(res.data.pharmacySelectionApproved)
                const lastPharmacySelectionRejected = Boolean(res.data.lastPharmacySelectionRejected);
                setPharmacies(res.data.externalPharmacies || []);
                setSelectedPharmacy(res.data.externalPharmacies.find((pharmacy: ExternalPharmacy) => Number(pharmacy.id) === Number(orderData.selectedEPharmacyID)))
                setIsPharmacySelectionApproved(pharmacySelectionApproved)
                if (lastPharmacySelectionRejected) {
                    setIsMenuOpen(true);
                }
            }).catch((err) => {
                window.clientLogger.error("error get pharmacy selection", err);                
            })
    }, []);

    const onSubmit = (selectedPharmacy: ExternalPharmacy) => {
        if (!selectedPharmacy) {
            return
        }
        const route = "account/update-pharmacy-selection";
        http.postToServer(route, {
            hashID: orderHashID,
            pharmacyID: selectedPharmacy.id
        }, [http.MIDDLEWARES.CUSTOMER_ID])
            .then((res) => {

                const alert = res.data.alert

                setStickyNotificationDetails((currentDetails: any) => ({
                    show: true,
                    title: alert.title,
                    content: alert.content,
                    level: alert.level,
                    showInfoIcon: false,
                    refresh: !currentDetails.refresh,
                }))

                if (alert.level === "success") {
                    setSelectedPharmacy(selectedPharmacy);
                    setIsMenuOpen(false);
                }
            })
            .catch((err) => {

                if (err.response?.data?.logLevel === "warn") {
                    window.clientLogger.warn("error submit pharmacy selection", err);
                }
                else {
                    window.clientLogger.error("error submit pharmacy selection", err);
                }

                setStickyNotificationDetails((currentDetails: any) => ({
                    show: true,
                    title: "Error",
                    content: "Die Apotheke konnte nicht ausgewählt werden.",
                    level: "error",
                    showInfoIcon: false,
                    refresh: !currentDetails.refresh
                }))
            })
            .finally(() => {
                setCurrentGlobalModalName(null);
            })

    }

    const handleMenuTriggerClick = useCallback(() => {
        if (!allowMenuTrigger) {
            return
        }

        //1. Send MP event for the state of the menu (send the opposite state)
        MainTracker.track('custom', 'Click on choose pharmacy.', {
            state: !isMenuOpen ? "opened" : "closed",
            orderID: orderHashID
        });

        //2. Change the state of the menu
        setIsMenuOpen(current => !current);

        //3. clear search
        setSearchQuery("");

    }, [allowMenuTrigger])


    const onSearchQueryChange = useCallback((e: any) => {
        setSearchQuery(e.target.value);
    }, [])

    const handlePharmacySelection = useCallback((pharmacy: ExternalPharmacy) => {
        const selection = pharmacies.find(_pharmacy => _pharmacy.id === pharmacy.id);
        const rejectionCount = selection?.rejectionCount || 0;
        const isExpired = selection?.isExpired || false;

    
        const trackEvent = (event: string) => {
            MainTracker.track('custom', event, {
                externalPharmacy: pharmacy,
                orderID: orderHashID,
            });
        }

        if (isExpired) {
            setStickyNotificationDetails((currentDetails: any) => ({
                show: true,
                title: "Bitte wählen Sie eine andere Apotheke",
                content: "Diese Apotheke kann leider nicht ausgewählt werden, da sie seit mehr als 3 Tagen nicht auf Ihre Anfrage geantwortet hat.",
                level: "warning",
                showInfoIcon: false,
                refresh: !currentDetails.refresh,
                backgroundColor: "#eadb28",
            }));
            setCurrentGlobalModalName(null);
            return;
        }
        
        if (rejectionCount >= 2) {
            
            setStickyNotificationDetails((currentDetails: any) => ({
                show: true,
                title: "Bitte wählen Sie eine andere Apotheke",
                content: "Diese Apotheke kann leider nicht ausgewählt werden, da sie Ihre Bestellung zu oft abgelehnt hat.",
                level: "warning",
                showInfoIcon: false,
                refresh: !currentDetails.refresh,                
                backgroundColor: "#eadb28",
            }));
            trackEvent('Send prescription twice');
            setCurrentGlobalModalName(null);
            return;
        }
    
        if (rejectionCount >= 1) {
            trackEvent('Choose Same pharmacy');
        }

        else {
            trackEvent('External pharmacy selected');
        }
    
        setSelectedPharmacyID(pharmacy.id);
        setCurrentGlobalModalName({
            type: "PHARMACY_SELECTION_CONFIRMATION",
            selectedPharmacy: pharmacy,
            onSubmit: () => onSubmit(pharmacy),
            rejectionCount,
        });
    }, [pharmacies, orderHashID, onSubmit]);

    const handlePharmacyRequest = useCallback(() => {
        MainTracker.track('pv', 'View Pop Up', { 'Pop Up Name': 'Submit new redemption pharmacy' })
        setCurrentGlobalModalName({
            orderHashID,
            type: "PHARMACY_REQUEST",
            showSuccessMessage: ({ name, city, email }: PharmacyInfo, disclaimerType: string) => setCurrentGlobalModalName({
                type: "PHARMACY_REQUEST_SUCCESS_NOTIFICATION", pharmacyInfo: { name, city, email }, disclaimerType, setSearchQuery
            }),
            setStickyNotificationDetails
        })
    }, [])


    return (
        <>
            <div id='pharmacy-selection-container' style={{ borderBottom: "1px solid #eef1f6" }} className='bg-white text-sm rounded-[6px]'>
                <div id='pharmacy-selection-header' className='flex flex-col md:flex-row items-center justify-between py-[14px] px-[20px] '>
                    <div className='title-value-container'>
                        <p className='account-order-detail-row-title'> Apotheke </p>
                        <p className='account-order-detail-row-value'>{selectedPharmacy?.name || "-"}</p>
                        {selectedPharmacy && <p className='account-order-detail-row-value'>{selectedPharmacy.address} </p>}

                        {/* Once the order is already sent to pharmacy (allowMenuTrigger is false) we write that in order to inform the customer */}
                        {!allowMenuTrigger && !isPharmacySelectionApproved && <p className='account-order-detail-row-value break-words mt-[4px]'>* Ihr Rezept wurde genehmigt und an die Apotheke Ihrer Wahl geschickt. Sobald die Apotheke Ihre Bestellung angenommen hat, erhalten Sie eine E-Mail und können das Medikament in der Apotheke Ihrer Wahl bezahlen und abholen. </p>}

                    </div>

                    <div id='change-pharmacy-menu-trigger'
                        onClick={handleMenuTriggerClick}
                        className={` ${allowMenuTrigger ? "bg-[#11DDAC] text-white cursor-pointer" : "bg-gray-200 text-[#9295a0] cursor-not-allowed"} flex items-center justify-center gap-2 mt-[20px] md:mt-0 py-[15px] px-[40px] text-md font-semibold rounded-[3px] whitespace-nowrap h-fit `}>
                        Apotheke auswählen
                        {(isMenuOpen && allowMenuTrigger) ? <FaChevronUp className='mt-[1px]' /> : <FaChevronDown className='mt-[1px]' />}
                    </div>


                </div>

                {isPharmacySelectionApproved && !allowMenuTrigger &&
                    <div id='disclaimer' className="p-4 bg-blue-200 rounded-md flex items-center justify-center text-sm w-5/6 m-auto mb-3 ">
                        <p id='pharmacy-selection-disclaimer' className="font-medium">
                            BITTE BEACHTEN SIE: Die ausgewählte Apotheke hat Ihr Rezept bereits angenommen. Sie können daher keine andere Apotheke wählen. Bitte holen Sie Ihr Medikament in der ausgewählten Apotheke ab. Sie können aber die Apotheke bitten, Ihr Rezept abzulehnen, damit sie eine andere Apotheke auswählen können, die Ihr Rezept einlöst.
                        </p>

                    </div>}


                {
                    (isMenuOpen && allowMenuTrigger) ?
                        <> 
                            <div id='search-pharmacy-container' style={{ borderTop: "1px solid #eef1f6", borderBottom: "1px solid #eef1f6" }} className='flex flex-col px-[20px] py-[14px] gap-4 select-none'>

                                {/* Search Bar */}
                                <div id='search-pharmacy-input' style={{ border: "1px solid #E5E5E5" }} className='flex items-center gap-2 p-2 rounded-md justify-between '>
                                    <img id='search-icon' className=" bg-[#E7FCF7] rounded-full h-[28px] w-[28px] flex justify-center items-center p-[4px]" src={require('../../../../../icons/Search-Green.svg').default} alt="search" />
                                    <input onChange={onSearchQueryChange} type="text" className='w-full py-2 border-none bg-transparent outline-none text-[14px] text-[#0d2c54] placeholder:text-[16px] placeholder:text-[#9D9D9D] ' placeholder='Hier Eingeben' />
                                </div>

                                {/* Search Bar Info  */}
                                <div id='search-pharmacy-info' className='flex flex-col xl:flex-row justify-between text-center xl:text-lefft'>
                                    <span className='font-poppins text-[#9D9D9D] text-[13px] font-normal'>Name der Apotheke, Postleitzahl oder Ort eingeben</span>
                                    <div className='sm:block flex flex-col'>
                                        <span className='text-center text-[13px] text-[#0d2c54] xl:gap-2 '>
                                            {translator.translate(language, "my_account", 'PrescriptionRedemptoin/cannot_find_pharmacy')}
                                        </span>
                                        <span id='pharmacy-request-button' className='text-[#11DDAC] underline cursor-pointer hover:no-underline ml-2 whitespace-break-spaces' onClick={handlePharmacyRequest}>{translator.translate(language, "my_account", 'PrescriptionRedemptoin/nofify_us')}</span>
                                    </div>
                                </div>
                            </div>

                            <div id='disclaimer' className='rounded-[3px] flex gap-1 bg-[#E8F1FF] p-3 m-6 text-[#4B91FA] font-roboto font-bold text-[14px]'>
                                <MdInfoOutline size={60} className='text-[#4B91FA] mx-1 h-6' />

                                <p>Nachstehend finden Sie die Verfügbarkeit des von Ihnen ausgewählten Produkts in verschiedenen Apotheken. Die Informationen stammen direkt von der Apotheke. Bei Fragen zur Verfügbarkeit oder zu Aktualisierungen wenden Sie sich bitte direkt an die Apotheke. Sobald Sie eine Apotheke ausgewählt haben, erhalten Sie per E-Mail weitere Anweisungen.</p>
                            </div>


                            {/* All pharmacies options   */}
                            <div
                                id='pharmacy-selection'
                                className='grid grid-cols-1 sm:grid-cols-2 max-h-[325px] my-6 overflow-y-auto bg-white rounded-[3px] px-[20px] gap-2  '
                            >

                                {filteredPharmacies.length > 0 ?
                                    filteredPharmacies.map((pharmacy) => {
                                        return (
                                            <div
                                                key={pharmacy.id}
                                                id={pharmacy.id?.toString()}
                                                className='bg-white cursor-pointer flex items-center gap-4 p-2 select-none'
                                                onClick={() => handlePharmacySelection(pharmacy)}
                                            >
                                                <RadioSelect selected={selectedPharmacyID === pharmacy.id} disabled={false} />

                                                <div id='external-pharmacy-info' className='w-full'>
                                                    <div className='flex justify-between w-full mb-1'>
                                                        <p id='external-pharmacy-name' className='text-[13px] font-medium text-[#0d2c54] leading-[125%]'>{pharmacy?.name}</p>
                                                        <AvailabilityTag availability={pharmacy?.availability} />
                                                    </div>
                                                    <p id='external-pharmacy-address' className='text-[12px] font-normal text-[#0d2c54] mt-[-4px]'>{pharmacy?.address}</p>
                                                </div>
                                            </div>
                                        );
                                    })
                                    :
                                    <p id='external-pharmracy-not-found' className='text-center text-[#0d2c54] font-normal text-[14px]'>
                                        Wir konnten diese Apotheke nicht finden
                                    </p>
                                }
                            </div>
                        </>
                        :
                        null
                }
            </div>


        </>
    )
}


const AvailabilityTag = ({ availability }: { availability: string }) => {
    let className = "px-1 font-poppins text-[13px] font-sm flex-none max-h-[20px] rounded-[5px] font-medium ";
    let text = "";

    switch (availability) {
        case "0":
            className += "bg-[#F9F3C7] text-[#8D7C01]";
            text = "Keine Info" //"No information";
            break;
        case "1":
        case "2":
            className += "bg-[#CFFEDC] text-[#2C8B47]";
            text = "Sofort Verfügbar" //"In stock";
            break;
        default:
            className += "bg-[#FBC7C7] text-[#C40000]";
            text = "Nicht lieferbar" //"Out of stock";
            break;
    }


    return <p className={className}>{text}</p>
}

export default PharmacySelection
