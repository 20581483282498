import React from 'react'

type Props = {
    inStock: boolean
    className?: string
}

const ProductAvailabilityTag:React.FC<Props> = ({inStock, className}) => {
  return (
    <div id='product-availability-tag' className={`text-white text-sm text-center rounded-[3px] px-1 shadow-lg ${inStock ? "bg-[#19D845]  shadow-[#19D845]/20" : "bg-blue-500 shadow-blue-500/20"} ${className}`}>
      {
        inStock ? "Premium" : "Regular"
      }
    </div>
  )
}

export default ProductAvailabilityTag
