import React, { useContext, useMemo } from 'react'
import { getPrice, translate } from '../utils/translator'
import { useParams } from 'react-router-dom'
import { useCartContext } from '../customHooks/useCart'
import { ShippingDataContext } from '../pages/Shipping/Shipping'

type Props = {

}

const CartViewSummary: React.FC<Props> = () => {

    const { language } = useParams()
    const { getCart } = useCartContext();
    const cart = getCart();
    // const DISCOUNTED_SHIPPING_FEE = 10;
    
    const { cannabisIDState } = useContext(ShippingDataContext) || {};

    const summaryRows = useMemo(() => {

        const totalDrugsPrice = cart.only_prescription ? 0 : cart.products.reduce((sum, cartProduct) => sum += cartProduct.item_price, 0);

        return {

            treatment_fee: {
                shouldShow: true,
                value: cart.treatment_fee || 0, //Treatment fee defined as the maximum value of the treatment fee of all products in the cart
            },

            total_drugs: {
                shouldShow: Boolean(!cart.only_prescription),
                value: cart.only_prescription ? 0 : totalDrugsPrice
            },

            cannabis_id: {
                shouldShow: cannabisIDState?.isCannabisIDAddedToOrder ?? false,
                value: cart.only_prescription && cannabisIDState?.cost ? cannabisIDState.cost : 0,
            },

            shipping_fee: {
                shouldShow: true,
                value: 0,
            },

            coupon: {
                shouldShow: Boolean(cart.bonus_id),
                value: (-1) * Number(cart.bonus_value),
            }
        }
    },[cart, cannabisIDState?.isCannabisIDAddedToOrder, cannabisIDState?.cost]);

    const shippingFeeRowElement = useMemo(() => {
        let text;
        let value = 0;

        if (cart.self_pickup_pharmacy_id ) {
            text = translate(language, "default", "OrderWindow/self_pickup");
        }

        else if (cart.only_prescription) { //TODO: check if it's subsidized (mainly for FR catalogs) and if so, we need to show the shipping fee
            // text = translate(language, "default", "OrderWindow/prescription_delivery");
            // value = DISCOUNTED_SHIPPING_FEE
            return <></>

        } else {
            text = translate(language, "default", "OrderWindow/next_day_delivery");
        }

        return (
            <li id="cart-view-summary-row" className="flex justify-between items-center text-sm text-[#656D79]">
                <p id="cart-view-summary-row-text" className="font-roboto"> {text} </p>
                <span id='cart-view-summary-row-value' className={`font-roboto ${value !== 0 ? "line-through" : ""}`}>
                    {getPrice(value, cart.locale_id)}
                </span>
            </li>
        )

    }, [cart])


    const cartTotalPrice = useMemo(() => {

        //Sum the total of summaryRows
        let sum = 0;
        
        Object.entries(summaryRows).forEach(([field, summaryRow]) => {
            if (summaryRow.shouldShow) {
                sum += summaryRow.value;
            }
        })

        return sum

    }, [cart, language, summaryRows]);



    return (
        <ul id='cart-view-summary' className='flex flex-col gap-y-1 pt-6'>

            {/* Treatment Fee  */}
            {
                summaryRows["treatment_fee"].shouldShow &&
                <li id="cart-view-summary-row" className="flex justify-between items-center text-sm text-[#656D79]">
                    <p id="cart-view-summary-row-text" className="font-roboto">
                        {translate(language, "default", "OrderWindow/treatment_fee")}
                    </p>
                    
                   <span id='cart-view-summary-row-value' className="font-roboto">
                        {getPrice(summaryRows["treatment_fee"].value, cart.locale_id)}
                    </span>
                </li>
            }


            {/* Drugs Price  */}
            {
                summaryRows["total_drugs"].shouldShow &&
                <li id="cart-view-summary-row" className="flex justify-between items-center text-sm text-[#656D79]">
                    <p id="cart-view-summary-row-text" className="font-roboto">
                        {translate(language, "default", "OrderWindow/drug_price")}
                    </p>
                    <span id='cart-view-summary-row-value' className="font-roboto">
                        {getPrice(summaryRows["total_drugs"].value, cart.locale_id)}
                    </span>
                </li>
            }

            {/* Shipping Fee  */}
            {summaryRows["shipping_fee"].shouldShow && shippingFeeRowElement}


            {/* Digital Cannabis ID Price row  */}
            {summaryRows["cannabis_id"].shouldShow &&
                <li id='cart-view-summary-row' className='flex justify-between items-center text-sm text-[#656D79]'>
                    <p className='font-roboto'>Digitaler Cannabis-Ausweis</p>
                    <span id='cart-view-summary-row-value' className="font-roboto"> {getPrice(summaryRows["cannabis_id"].value, cart.locale_id)} </span>
                </li>
            }


            {/* Coupon discount */}
            {
                summaryRows["coupon"].shouldShow &&
                <li id='cart-view-summary-row' className='flex justify-between items-center text-sm text-[#656D79]'>
                    <p className='font-roboto'> Coupon </p>
                    <span className='font-roboto'> {getPrice(summaryRows["coupon"].value, cart.locale_id)} </span>
                </li>
            }


            {/* Cart total Price */}
            <li id='cart-view-summary-row' className='flex justify-between items-center text-sm text-[#656D79] mt-2'>
                <p className='font-roboto font-semibold text-[#0D2C54] text-lg'> {translate(language, "default", "OrderWindow/total_price")} </p>
                <span id="cart_final_price" className='font-roboto font-semibold text-[#0D2C54]'> {getPrice(cartTotalPrice, cart.locale_id)} </span>
            </li>
            
        </ul>
    )
}

export default CartViewSummary