import { createContext , useContext, useEffect, useMemo, useState } from 'react'
import ProductInfoCard from './components/ProductInfoCard';
import * as http from "../../../utils/http";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProductSelectionPanel from './components/ProductSelectionPanel';
import Legend from './components/Legend';
import "./FreeDosageLayout.css"
import SubmitButton from './components/SubmitButton';
import { loadCustomerFromSession } from '../../../reducers/CustomerReducer';
import HeaderController from '../../../components/Headers/HeaderController';
import Footer from '../../../components/Footer/Footer';
import LoadingModal from '../../../components/Modals/LoadingModal/LoadingModal';
import ServerError from '../../../components/ServerError/ServerError';
import StickyNotification from '../../../components/StickyNotification/StickyNotification';
import { mergeArrays } from '../helpers';
import { CatalogSettingContext } from '../ChooseTreatmentRouter';
import Disclaimer from '../components/Disclaimers/Disclaimer';
import { MainTracker } from '../../../PixelTrackers/MainTracker';
import * as translator from "../../../utils/translator";


export interface ProductItem {
  product_item_id: number,
  quantity: number,
  price: number,
  in_stock: boolean,
  custom_quantity_title: string,
}

export interface Product {
  product_id: number,
  treatment_fee: number,
  catalog_id: number
  dosage_str: string,
  title: string,
  additional_data?: string,
  product_settings?: { [key: string]: Array<string> }
  in_stock: boolean,
  items: ProductItem[],
  quantity_type:string
}


interface ChooseTreatmentContextType {
  products: Product[],
  selectedProductItemID: null | number,
  setSelectedProductItemID: React.Dispatch<React.SetStateAction<null | number>>,
  selectedProductID: null | number,
  setSelectedProductID: React.Dispatch<React.SetStateAction<null | number>>,
  selectedProduct: Product | null, //TODO TYPE THIS
  onSubmit: () => void,
  searchTerm: string,
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
  categoryLimitation: null | { monthlyLimitation: number, purchasedQuantity: number },
  selectedProductItem: null | ProductItem,
  isQuantityExceeded: () => boolean,
  submitLoading: boolean,
  filterData: { [key: string]: { value: string, isSelected: boolean }[] },
  toggleFilter: (category: string, value: string) => void,
  isFilterMenuOpen: boolean,
  setIsFilterMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
  PurchaseTierFilter: "all" | "premium" | "regular"
  setPurchaseTierFilter: React.Dispatch<React.SetStateAction<"all" | "premium" | "regular">>
  resetAllFilters: () => void,
  appliedFiltersCount: number,
  filteredProducts: Product[],
}

export const getMinPricePerQuantity = (product: Product) => {
  let minPrice = Infinity;
  let quantity = 1;

  product?.items.forEach((productItem: any) => {
    if ((productItem.price / productItem?.quantity) < (minPrice / quantity)) {
      minPrice = productItem?.price;
      quantity = productItem?.quantity;
    }
  })

  return toLocalePrice(minPrice / quantity);
}

export const getMinPricePerProduct = (product: Product) => {
  let minPrice = Infinity;

  product?.items.forEach((productItem: any) => {
    if (productItem?.price < minPrice) {
        minPrice = productItem?.price;
    }
});

  return toLocalePrice(minPrice);
}

export const getMaxPricePerQuantity = (product: Product) => {
  let maxPrice = 0;
  let quantity = 1;

  product?.items.forEach((productItem: any) => {
    if ((productItem.price / productItem?.quantity) > (maxPrice / quantity)) {
      maxPrice = productItem?.price;
      quantity = productItem?.quantity;
    }
  })

  return toLocalePrice(maxPrice / quantity);
}

export const toLocalePrice = (price: number, locale = 'de-DE') => {
  if (!price) return "";
  return price.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export const ChooseTreatmentContext = createContext<ChooseTreatmentContextType | null>(null);

const FreeDosageLayout = () => {

  const { country, language, category } = useParams() as {
    category: string;
    country: string;
    language: string;
  };

  const routerContext = useContext(CatalogSettingContext) as any;


  const initFilters = (products: Product[]) => {
    const filterData: any = {}
    for (const product of products) {
      if (product.product_settings) {
        for (const [key, value] of Object.entries(product.product_settings)) {
          if (!filterData[key]) {
            filterData[key] = []
          }

          const filterOptions = value.map((item) => ({
            value: item,
            isSelected: false
          }))

          filterData[key] = mergeArrays(filterData[key], filterOptions, (a: { value: string }) => a.value)
        }
      }
    }

    return filterData;
  }


  const toggleFilter = (category: string, value: string) => {
    setFilterData((prevFilterData) => {
      const newFilterData = JSON.parse(JSON.stringify(prevFilterData));
      newFilterData[category] = newFilterData[category].map((item: { value: string, isSelected: boolean }) => {
        if (item.value === value) {
          return { ...item, isSelected: !item.isSelected };
        }
        return item;
      });
      return newFilterData;
    })
  }

  const initStickyNotification = () => {
    if (searchParams.get("limit_disclaimer") && categoryLimitation) {
      const remainingPossibleQuantity = categoryLimitation?.monthlyLimitation - categoryLimitation?.purchasedQuantity;
      const content = `Ihr persönliches Limit ist derzeit: ${remainingPossibleQuantity > 0 ? remainingPossibleQuantity : 0} Gramm. Das generelle Limit beträgt ${categoryLimitation?.monthlyLimitation} Gramm pro Monat.`
      return { show: true, title: "Monatliches Limit überschritten", content, level: "disclaimer", refresh: false };
    } else {
      return { show: false, title: "", content: "", level: "", refresh: false };
    }
  }

  const [searchParams,] = useSearchParams();
  const [selectedProductItemID, setSelectedProductItemID] = useState<null | number>(1);
  const [selectedProductID, setSelectedProductID] = useState<null | number>(1);
  const [products, setProducts] = useState<Product[]>([]);
  const [categoryLimitation, setCategoryLimitation] = useState<{ monthlyLimitation: number, purchasedQuantity: number } | null>(null);
  const [submitLoading, setSubmitLoading] = useState(false);


  const [filterData, setFilterData] = useState<{ [key: string]: { value: string, isSelected: boolean }[] }>({});
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [PurchaseTierFilter, setPurchaseTierFilter] = useState<"all" | "premium" | "regular">("all");


  const appliedFiltersCount = useMemo(() => {
    let count = 0;

    if (!filterData) return 0;

    count += Object.values(filterData).flat().filter((item) => item.isSelected).length;

    if (PurchaseTierFilter !== 'all') count++;

    return count;
  }, [filterData, PurchaseTierFilter])

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [stickyNotification, setStickyNotification] = useState<{ refresh: boolean, level: string, show: boolean, title: string, content: string }>(initStickyNotification())

  const selectedProduct = products.find((product) => product.product_id === selectedProductID) || null;
  const selectedProductItem = selectedProduct?.items.find((item: any) => item.product_item_id === selectedProductItemID) || null;

  const filteredProducts = useMemo(() => {

    let filtered = products;
    const purchaseTier = PurchaseTierFilter;

    if (searchTerm) {
      // Create a regex that allows any characters between words in the search term (case-insensitive).
      const regex = new RegExp(searchTerm.split(' ').join('.*'), 'i');  
      filtered = filtered.filter((product) => regex.test(product.title));
    }

    if (purchaseTier === 'premium') {
      filtered = filtered.filter((product) => product.in_stock)
    } else if (purchaseTier === 'regular') {
      filtered = filtered.filter((product) => !product.in_stock)
    }

    if (!filterData) return filtered;

    Object.keys(filterData).forEach((key) => {

      const whitelist = filterData[key].filter((item) => item.isSelected).map((item) => item.value);

      if (!whitelist.length) return;

      filtered = filtered.filter((product) => {
        const productSetting = product.product_settings;
        return productSetting && productSetting[key]?.some((item) => whitelist.includes(item));
      });
    })

    return filtered;

  },[products, filterData, searchTerm, PurchaseTierFilter])


  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    http.getFromServer(`choose-treatment-free-dosage/${category}/${language}/${country}`,
      [
        http.MIDDLEWARES.CUSTOMER_ID,
        http.MIDDLEWARES.CART_ORDER
      ])
      .then((response) => {
        const serverData = response.data;
        setCategoryLimitation(serverData.categoryLimits);
        setProducts(serverData.products);
        setFilterData(initFilters(serverData.products));
        setDefaultProductID(serverData.products);
      })
      .catch((error) => {
        setError(500);
        window.clientLogger.error("error get FreeDosageLayout",error)
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])


  useEffect(() => {
    setStickyNotification(initStickyNotification());
  }, [searchParams, categoryLimitation])


  const isQuantityExceeded = () => {
    if (categoryLimitation && selectedProductItem) {
      return selectedProductItem?.quantity + categoryLimitation?.purchasedQuantity > categoryLimitation?.monthlyLimitation;
    } else {
      return false
    }
  }


  const onSubmit = async () => {
    setSubmitLoading(true);
    const prescriptionOnly = !selectedProduct?.in_stock;
    const purchaseType = prescriptionOnly ? "Regular" : "Premium";
    MainTracker.superProperties({ "Product Name": selectedProduct?.title, "ProductItemID": selectedProductItemID, "Purchase Type":  purchaseType });

    http.postToServer("choose-treatment-free-dosage", {
      cartProductsDTOs: [{
        quantity: 1,
        product_item_id: selectedProductItemID,
        only_prescription: prescriptionOnly,
        catalog_id: selectedProduct?.catalog_id,
        product_in_stock: selectedProduct?.in_stock
      }],
      selectedProductItemID,
      catalogID: selectedProduct?.catalog_id,
      prescriptionOnly
    }, [http.MIDDLEWARES.CUSTOMER_ID, http.MIDDLEWARES.CART_ORDER])
      .then((response) => {
        let customerFromSession = loadCustomerFromSession();
        if (!customerFromSession) {
          // if no customer id send to registration
          navigate({ pathname: `/sign_up/${country}/${language}` });
        } else {
          navigate({ pathname: `/shipping/${country}/${language}` });
        }
      })
      .catch((err) => {
        const code = err?.response?.data?.code;

        if (code === "monthly_limit_exceeded" && categoryLimitation) {
          const remainingPossibleQuantity = categoryLimitation?.monthlyLimitation - categoryLimitation?.purchasedQuantity;
          const content = `Ihr persönliches Limit ist derzeit: ${remainingPossibleQuantity > 0 ? remainingPossibleQuantity : 0} Gramm. Das generelle Limit beträgt ${categoryLimitation?.monthlyLimitation} Gramm pro Monat.`
          setStickyNotification((current) => ({ show: true, title: "Monatliches Limit überschritten", content, level: "disclaimer", refresh: !current.refresh }));
        } else {
          setStickyNotification((current) => ({ show: true, title: "Server Failed", content: "", level: "error", refresh: !current.refresh }));
        }
      })
      .finally(() => setSubmitLoading(false));
  }

  const freeDosageContext = {
    products,
    selectedProductItemID,
    setSelectedProductItemID,
    selectedProductID,
    setSelectedProductID,
    selectedProduct,
    selectedProductItem,
    searchTerm,
    setSearchTerm,
    onSubmit,
    categoryLimitation,
    isQuantityExceeded,
    submitLoading,
    filterData,
    toggleFilter,
    isFilterMenuOpen,
    setIsFilterMenuOpen,
    PurchaseTierFilter,
    setPurchaseTierFilter,
    appliedFiltersCount,
    filteredProducts,
    resetAllFilters: () => {
      setFilterData(initFilters(products));
      setPurchaseTierFilter('all');
    }
  }


  const setDefaultProductID = (products: Product[]) => {
    let productIsSelected = false;
    for (const product of products) {
      if (product.in_stock) {
        const filteredProductItems = product.items.filter((item: any) => item.in_stock);
        setSelectedProductID(product.product_id);
        const medianItemIndex = Math.floor(filteredProductItems.length / 4);  //get 25th percentile item
        setSelectedProductItemID(filteredProductItems[medianItemIndex].product_item_id);
        productIsSelected = true;
        break;
      }
    }
    if (!productIsSelected) {
      setSelectedProductID(products[0].product_id);
      const medianItemIndex = Math.floor(products[0].items.length / 4);
      setSelectedProductItemID(products[0].items[medianItemIndex].product_item_id);
    }
  }


  const showERezeptDisclaimer = routerContext?.showERezeptDisclaimer;

  return (
    <ChooseTreatmentContext.Provider value={freeDosageContext}>
      {loading && <LoadingModal />}

      <StickyNotification
        showNotification={stickyNotification.show}
        title={stickyNotification.title}
        onClick={() => setStickyNotification({ show: false, title: "", content: "", level: "", refresh: false })}
        content={stickyNotification.content}
        level={stickyNotification.level}
        refresh={stickyNotification.refresh}
      />

      {error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={error} />
        </>
      )}

      {!loading && !error && <>
        {category && <HeaderController language={language} stage="Treatment" />}
        <h1 id='main_title' className='flex justify-center pt-5 text-[#0D2C54] bg-[#F7F7F7]'>Behandlung wählen</h1>
        <Legend />
        <div id='main-container' className='bg-[#F7F7F7] flex min-h-screen p-5 justify-center'>


          <div id='panels-container' className='grid grid-col-1  md:grid-cols-2 gap-8  h-full justify-items-end' >
            {/* left panel */}
            <div id='left-panel' className='px-1 py-2  w-full md:max-w-[500px]'>
              <ProductSelectionPanel products={products} languageID={language} />
            </div>
          
            {/* right panel */}
            <div id='right-panel' className='max-md:hidden   max-w-[450px] flex-col gap-5 '>
              <ProductInfoCard inStock={selectedProduct?.in_stock!} />
              <SubmitButton 
                languageID={"de"} 
                disabled={submitLoading} 
                desktop 
                onClick={onSubmit}
               />
              <div id='significance_under' className='max-md:hidden mt-5 flex'>
                {showERezeptDisclaimer && <Disclaimer text={translator.translate(language, "default", "ChooseTreatment/e-recipe-significance")} />}
              </div>
            </div>
          </div>


        </div>
        {category && <Footer showDiscountDisclaimer={true} page="ChooseTreatment" language={language} />} {/*shows footer in normal flow but not in modal*/}
      </>}
    </ChooseTreatmentContext.Provider>
  )
}

export default FreeDosageLayout