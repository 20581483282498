import "./ChooseTreatmentLandingPage.css";
import * as http from "../../../utils/http";
import HeaderController from "../../../components/Headers/HeaderController";
import DrugChoicePanel from "../components/DrugChoicePanel/DrugChoicePanel";
import ChooseTreatmentLandingPageDrugInfo from "../components/ChooseTreatmentLandingPageDrugInfo/ChooseTreatmentLandingPageDrugInfo";
import TreatmentOptionsInfo from "../components/TreatmentOptionsInfo/TreatmentOptionsInfo";
import { clientConfig } from "../../../config";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, createContext, useContext } from "react";
import Footer from "../../../components/Footer/Footer";
import { CatalogSettingContext } from "../ChooseTreatmentRouter";
import LoadingModal from "../../../components/Modals/LoadingModal/LoadingModal";
import ServerError from "../../../components/ServerError/ServerError";
import { MainTracker } from "../../../PixelTrackers/MainTracker";
import StickyNotification from "../../../components/StickyNotification/StickyNotification";
import { useCartContext } from "../../../customHooks/useCart";


let doctorImg = clientConfig.IMAGES_URL + "uploads/doctor/6/Hasan%20Igde.png";

export const CartDataContext = createContext();
export const StickyNotificationContext = createContext();

const ChooseTreatmentLandingPage = () => {
  let selectedDrug = "";
  let selectedDosage = "";

  const catalogSettings = useContext(CatalogSettingContext);

  const { country, language, category } = useParams();

  const { getSessionFields } = useCartContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);
  const [productKeys, setProductKeys] = useState([]);
  const [listOfDosages, setListOfDosages] = useState([]);
  const [listOfPackets, setListOfPackets] = useState([]);
  const [defaultDosages, setDefaultDosages] = useState({});
  const [minPrices, setMinPrices] = useState([]);
  const [selecetedDrugState, setSelectedDrugState] = useState();
  const [productFullTitles, setProductFullTitles] = useState([]);
  const [drugImagePath, setDrugImagePath] = useState("");
  const [productsDescriptions, setProductsDescriptions] = useState([]);
  const [isExpressReorder,setIsExpressReorder] = useState(false);

  const [currentProductDescription, setCurrentProductDescription] =
    useState("");
  const [pricesLogistics, setPricesLogistics] = useState();
  const [productItemSubscriptionPlans, setProductItemSubscriptionPlans] =
    useState({});

  const [currentSelectedProductItemIndex, setCurrentSelectedProductItemIndex] =
    useState(null);
  const [currentlySelectedDosageTitle, setCurrentlySelectedDosageTitle] =
    useState("");

  const [showStickyNotification, setShowStickyNotification] = useState({
    show: false,
    title: "",
    content: "",
  });

  const handleErrorNotification = (title, content) => {
    if (!title && !content) {
      setShowStickyNotification({ show: false, title: "", content: "" });
    } else {
      setShowStickyNotification({ show: true, title, content });
    }
  };

  useEffect(() => {
    let sessionCart = getSessionFields();

    if (!sessionCart.mention || sessionCart.mention !== category) {
      setError(400);
      setLoading(false);  
      window.clientLogger.warn('Warn in getfromServer - ChooseTreatmentLandingPage', "Mention couldn't be found in Session cart");

    } else {
      http
        .getFromServer(`products/${country}/${language}/${category}/true`, [
          http.MIDDLEWARES.CUSTOMER_ID,
          http.MIDDLEWARES.CART_ORDER
        ])
        .then((response) => {
          let productsData = response.data.productsData;
          if(response.data.cart.is_express_reorder){
            setIsExpressReorder(true); //setting is express reorder flow to be true so we don't show coupon discount blocks
          }
          
          setLoading(false);
          setProducts(productsData.products);
          setProductKeys(Object.keys(productsData.products));
          setDefaultDosages(productsData.defaultDosages);
          setMinPrices(productsData.minPrices);
          selectedDrug = Object.keys(productsData.products)[0];

          setSelectedDrugState(selectedDrug);
          let currentDosage = Object.keys(
            productsData.products[selectedDrug]
          )[0];
          let currentDrug = Object.keys(productsData.products)[0];

          setListOfDosages(Object.keys(productsData.products[currentDrug]));
          setListOfPackets(productsData.products[currentDrug][currentDosage]
            .map((packet) => {
              return {...packet, original_in_stock : packet.in_stock}
            }));

          setProductFullTitles(productsData.drugFullTitles);
          setDrugImagePath(productsData.drugImagesPath);
          setProductsDescriptions(productsData.productDescriptions);
          setCurrentProductDescription(
            productsData.productDescriptions[currentDrug]
          );
          setPricesLogistics(productsData.pricesLogistics);
          setProductItemSubscriptionPlans(productsData.proudctItemPlans);
        })
        .catch((error) => {
          setError(500);
          setLoading(false)
          window.clientLogger.error('error in getfromServer - ChooseTreatmentLandingPage',error);
        });
    }
  }, []);

  const changeDosageList = (newDosageList) => {
    setListOfDosages(newDosageList);
  };

  const navigate = useNavigate();
  const navigationCallback = (pathname) => {
    navigate({
      pathname,
    });
  };

  const changePacketsList = (newPacketsList) => {
    setListOfPackets(newPacketsList.map((packet) => {
      return {...packet, original_in_stock : packet.in_stock}
    }));
  };

  const changeSelectedDosage = (newDosage) => {
    setCurrentlySelectedDosageTitle(newDosage);
    setListOfPackets(products[selecetedDrugState][newDosage].map((packet) => {
      return {...packet, original_in_stock : packet.in_stock}
    }));
  };

  const changeDosage = (dosage) => {
    setListOfPackets(products[selecetedDrugState][dosage].map((packet) => {
      return {...packet, original_in_stock : packet.in_stock}
    }));
  };

  const changeDrug = (drugID) => {
    // mixpanel


    MainTracker.superProperties({ "Product Name": drugID });
    MainTracker.track("click", "Click change Product Name");

    setListOfDosages(Object.keys(products[drugID]));
    selectedDrug = drugID;
    setSelectedDrugState(selectedDrug);
    changeDosageList(Object.keys(products[selectedDrug]));
    selectedDosage = Object.keys(products[selectedDrug])[0];

    setListOfPackets(products[selectedDrug][selectedDosage].map((packet) => {
      return {...packet, original_in_stock : packet.in_stock}
    }));
    setCurrentProductDescription(productsDescriptions[selecetedDrugState]);
    changeCurrentlySelectedProductItemIndex(null);
  };

  const changeCurrentlySelectedProductItemIndex = (newProductItemIndex) => {
    setCurrentSelectedProductItemIndex(newProductItemIndex);
  };

  return (
    <>
      {loading && <LoadingModal />}

      {error && (
        <>
          <HeaderController showSteps={false} stage={"Questionnaire"} />
          <ServerError status={error} />
        </>
      )}

      {!error && !loading && (
        <>
          <StickyNotification
            showNotification={showStickyNotification.show}
            title={showStickyNotification.title}
            onClick={(falseValue) => setShowStickyNotification(falseValue)}
            content={showStickyNotification.content}
          />

          <HeaderController language={language} stage="Treatment" />
          <div className="SelectTreatmentLandingPage-container">
            <div className="SelectTreatmentLandingPage-inner-container">

            <StickyNotificationContext.Provider value={handleErrorNotification}>

              <CartDataContext.Provider value={{ drugImagePath }}>
                <div className="left-panel">
                  <TreatmentOptionsInfo isExpressReorderFlow={isExpressReorder} doctorImg={doctorImg} />
                  <div className="DrugChoicePanel">
                    <DrugChoicePanel
                      currencySign={'€'}
                      products={products}
                      currentSelectedProductItemIndex={
                        currentSelectedProductItemIndex
                      }
                      changeCurrentlySelectedProductItemIndex={
                        changeCurrentlySelectedProductItemIndex
                      }
                      productItemSubscriptionPlans={
                        productItemSubscriptionPlans
                      }
                      pricesLogistics={pricesLogistics}
                      language={language}
                      landingPage={true}
                      currentProductDescription={currentProductDescription}
                      drugImagePath={drugImagePath}
                      selecetedDrugState={selecetedDrugState}
                      height={80}
                      drugFullNames={productFullTitles}
                      listOfPackets={listOfPackets}
                      selectedDrug={selecetedDrugState}
                      defaultDosages={defaultDosages}
                      listOfDosages={listOfDosages}
                      changeDosage={changeDosage}
                      changePacketsList={changePacketsList}
                      listOfProducts={products}
                      productKeys={productKeys}
                      changeDrugCallback={changeDrug}
                      minPrices={minPrices}
                      navigationCallback={navigationCallback}
                      enableNoPreferenceOption={
                        catalogSettings.isNoPrefereceTreatmentEnabled
                      }
                      country={country}
                    />
                  </div>
                </div>

                <ChooseTreatmentLandingPageDrugInfo
                  products={products}
                  navigationCallback={navigationCallback}
                  currentSelectedProductItemIndex={
                    currentSelectedProductItemIndex
                  }
                  changeCurrentlySelectedProductItemIndex={
                    changeCurrentlySelectedProductItemIndex
                  }
                  currentlySelectedDosageTitle={currentlySelectedDosageTitle} //currentlySelectedDosageTitle
                  changeSelectedDosage={changeSelectedDosage}
                  productItemSubscriptionPlans={productItemSubscriptionPlans}
                  pricesLogistics={pricesLogistics}
                  language={language}
                  minPrices={minPrices}
                  hideable={true}
                  drugDescription={currentProductDescription}
                  drugImagePath={drugImagePath}
                  selecetedDrugState={selecetedDrugState}
                  listOfPackets={listOfPackets}
                  changePacketsList={changePacketsList}
                />
              </CartDataContext.Provider>
              </StickyNotificationContext.Provider>
            </div>
          </div>
          <Footer showDiscountDisclaimer={true} page="ChooseTreatmentLandingPage" language={language} />
        </>
      )}
    </>
  );
};

export default ChooseTreatmentLandingPage;