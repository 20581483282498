import React, { forwardRef, useCallback, useContext, useState } from 'react'
import ProductAvailabilityTag from './ProductAvailabilityTag';
import { ChooseTreatmentContext, Product, toLocalePrice } from '../CannaleoFreeDosageLayout';
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import HoverCard from '../../../../components/HoverCard';


const MOBILE_BREAK_POINT = 768

interface Props {
    id: string;
    product: Product;
    price: string;
    hidePrices?: boolean
    hideTags?: boolean
    containerClassName?: string,
}


const ProductCard = forwardRef<HTMLDivElement, Props>(({ id, product, price, hidePrices = false, hideTags = false, containerClassName }, ref) => {

    const chooseTreatmentContext = useContext(ChooseTreatmentContext);
    const additionalDataObj = product.additional_data ? JSON.parse(product.additional_data) : {};
    const [shouldHoverExpand, setShouldHoverExpand] = useState(false);
    const isMaxNumOfProductSelected = chooseTreatmentContext?.selectedProductsCounter == chooseTreatmentContext?.maxCartProducts;
    const selectedProductItem = chooseTreatmentContext?.selectedProductItems.find((selectedItem) => selectedItem.catalog_id === product.catalog_id);
    const index = product.items.findIndex((item) => item.product_item_id === selectedProductItem?.product_item_id) || 0;
    const cardRef = ref as React.MutableRefObject<HTMLDivElement | null>;



    let customDosageForCannabis = ''
    let strain = ''
    let shouldUseCannabisDosage = false;

    if (additionalDataObj) {
        if (additionalDataObj['THC'] && additionalDataObj['CBD'] && additionalDataObj['strain']) {
            shouldUseCannabisDosage = true;
            customDosageForCannabis = `THC: ${additionalDataObj['THC']} | CBD: ${additionalDataObj['CBD']}`;
            strain = `Sorte: ${additionalDataObj['strain']}`
        }
    }

    const scrollToTop = () => {
        if (window.screen.width < MOBILE_BREAK_POINT) {
            setTimeout(() => {
                if (cardRef?.current) {
                    cardRef!.current!.scrollIntoView({ behavior: 'smooth', block: "start" });
                }
            }, 200)
        }
    }

    const getMaxPricePerQuantity = useCallback((product: Product | null) => {
        if (!product) return 0;

        if (product.items?.length > 0) {
            const firstItem = product.items[0];
            return firstItem?.price / firstItem?.quantity;
        };

        return 0;
    }, [product])

    const nextProduct = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (index < product.items.length - 1) {
            chooseTreatmentContext?.handleProductItemToggle(product.items[index + 1], "ADD");
        }
    }, [product, index])

    const previousProduct = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        if (index > 0) {
            chooseTreatmentContext?.handleProductItemToggle(product.items[index - 1], "ADD");
        }
    }, [product, index])


    const calculateSave = useCallback(() => {
        if (!selectedProductItem) {
            return 0;
        }

        const savePrice = selectedProductItem.quantity * maxPerGramCost - selectedProductItem.price;
        if (savePrice > 0) {
            return toLocalePrice(savePrice);
        }
    }, [product, selectedProductItem])


    const onToggle = useCallback((e: React.MouseEvent) => {

        e.stopPropagation();
        const toggleAction = product.isSelected ? "REMOVE" : "ADD";
        const maxCartProducts = chooseTreatmentContext?.maxCartProducts!;

        if (toggleAction === "ADD" && isMaxNumOfProductSelected && maxCartProducts > 1) {
            return
        }

        if (toggleAction === "ADD" && maxCartProducts > 1) {
            setShouldHoverExpand(toggleAction === "ADD")
        }

        // 1. Select the product 
        chooseTreatmentContext?.handleProductToggle(product, toggleAction);

        // 2. Scroll to top!
        scrollToTop();

    }, [product, shouldHoverExpand, chooseTreatmentContext?.selectedProducts, chooseTreatmentContext?.selectedProductItems])


    const maxPerGramCost = getMaxPricePerQuantity(product);
    const savedCost = calculateSave();


    return (

        <div
            id={id ? id : 'product-card-container'}
            data-product-id={product.product_id}
            onClick={onToggle}
            style={{ border: "1px solid #FFFFFF" }}
            ref={ref}
            className={`flex relative scroll-mt-[100px] md:mr-2 ${product.isSelected ? "" : "max-md: min-h-[110px]"} bg-white md:gap-2 p-4 pt-6 md:p-6  rounded-[8px] ${!isMaxNumOfProductSelected || product.isSelected || chooseTreatmentContext?.maxCartProducts === 1 ? "cursor-pointer" : "cursor-not-allowed"} ${containerClassName} `}>

            {/* HoverCard after clicking  */}
            {product.isSelected ? null : <HoverCard shouldExpand={shouldHoverExpand} title={product.title} mainText={"Erfolgreich Ihrer Liste hinzugefügt"} />}
            {hideTags ? null : <ProductAvailabilityTag inStock={product.in_stock} className='absolute -top-2 right-6 p-[3px]' />}

            {/* Content */}

            <div id='product-card-content' className='flex flex-col  gap-y-2 w-full'>

                {/* Header  */}
                <div id='proudct-card-header' className='flex items-start justify-between py-1 gap-1'>

                    <div id='product-card-title-and-checkbox' className='flex gap-1 items-start'>
                        {/* CheckBox  */}
                        {product.isSelected ? <MdCheckBox size={26} className='text-[#11DDAC] ' /> : <MdCheckBoxOutlineBlank size={26} className='text-[#11DDAC]' />}

                        {/* Title  */}
                        <p id="product-card-title" className='font-medium text-[#0D2C54] '>{product.title}</p>
                    </div>

                    {/* Selected Product item price  */}
                    {
                        product.isSelected && !hidePrices && selectedProductItem ?

                            <div className='p-1 flex items-end flex-col'>
                                <p className='text-[#0D2C54] font-medium flex min-w-fit'>{product?.in_stock ? toLocalePrice(selectedProductItem?.price) : toLocalePrice(product.treatment_fee!)} €</p>
                                {savedCost && selectedProductItem?.in_stock ? <p className='text-xs font-medium text-nowrap text-[#0A9281]'>Spare {savedCost} €</p> : null}
                            </div>

                            :
                            null
                    }

                    {
                        !product.isSelected && !hidePrices ?
                            <div id='product-card-prices-and-tags' className='flex flex-col min-h-10'>
                                <div>
                                    <div className='flex items-center gap-1'>
                                        <p className='text-xs text-[#556D8C] '>ab</p>
                                        <p className='text-xs font-medium text-[#0D2C54] whitespace-nowrap'>{price} €</p>
                                    </div>
                                    {product.quantity_type == 'gram' &&
                                        <p className='text-xs text-[#556D8C]'> pro Gramm </p>
                                    }
                                </div>
                            </div>
                            :
                            null
                    }


                </div>


                <div id='product-card-body' className='flex justify-between items-center '>

                    {/* Custom dosage text  */}
                    {
                        shouldUseCannabisDosage ?
                            <div id='product-card-custom-dosage' className='w-[95%] ' >
                                <p id='product-card-custom-dosage-text' style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{customDosageForCannabis}</p>
                                <p id='product-card-custom-dosage-strain' style={{ fontFamily: "Roboto, sans-serif" }} className='text-sm text-[#4D4D4D] font-medium'>{strain}</p>

                                    {Boolean(selectedProductItem && product.isSelected &&  product.in_stock && product.quantity_type == 'gram') 
                                    
                                    && <div className="text-[#0D2C54] text-[14px] font-normal font-poppins mt-4">
                                        ab <span className="font-semibold">{toLocalePrice(selectedProductItem!.price! / selectedProductItem!.quantity!)}</span> pro gramm
                                    </div>}
                            </div>
                            :

                            null
                    }


                    {/* Quantity Control  */}
                    {
                        product.isSelected && selectedProductItem ?
                            <div className='flex flex-col w-full gap-y-1'>

                                <div id='product-card-quantity-control' style={{ border: "1px solid #E5E5E5" }} className='flex justify-between w-full items-center rounded-md px-2 '>
                                    <div onClick={previousProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#11DDAC] text-center flex items-center justify-center hover:opacity-90 transition-opacity'>
                                        <span id='sub_quant'>
                                            -
                                        </span></div>

                                    <div className='flex flex-col justify-center items-center'>
                                        <p className='text-[#0D2C54] font-normal'>{selectedProductItem?.quantity}</p>
                                        <p className='text-[#98A0AF] text-sm'>{product.quantity_type == 'gram' ? 'Gramm' : 'Milliliter'}</p>
                                    </div>

                                    <div onClick={nextProduct} className='cursor-pointer select-none rounded-md h-8 w-8 text-white bg-[#11DDAC] text-center flex items-center justify-center hover:opacity-90 transition-opacity'>
                                        <span id="add_quant">+</span>
                                    </div>

                                </div>

                             
                            </div>

                            :
                            null
                    }


                </div>

            </div>





        </div>


    )
});

export default ProductCard