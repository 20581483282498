import { Fragment, useMemo, useState } from "react";
import { useEffect } from "react";
import HeaderController from "../../components/Headers/HeaderController";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import * as translator from "../../utils/translator"
import ThankyouMainTitleWidget from "./components/ThankyouMainTitleWidget/ThankyouMainTitleWidget";
import ThankyouStepsWidget from "./components/ThankyouStepsWidget/ThankyouStepsWidget";
import "./Thankyou.css";
import * as http from "../../utils/http";
import * as SessionStorageWrapper from "../../utils/SessionStorageWrapper"
import { MainTracker } from "../../PixelTrackers/MainTracker";
import { useParams, useSearchParams } from "react-router-dom";
import Trustedshops from "../../components/Trustedshops/Trustedshops";
import { ORDER_FLOWS } from "../../utils/consts";

export default function Thankyou() {

    const params = useParams();
    const hashID = params.order_hash_id;
    const language = params.language
    const country = translator.getCountryByLanguage(language)
    
    
    let [searchParams] = useSearchParams();
    let thankyouHash = searchParams.get('thankyouHash');
    const isOrderOnlyPrescription = searchParams.get("onlyPrescription") === "1" ? true : false;
    const isERecipe = searchParams.get("erecipe") === "1" ? true : false;
    const [isEPrescriptionOnly, setIsEPrescriptionOnly] = useState(false);
    const [isSelfPickUp, setIsSelfPickUp] = useState(false);
    const [isCannaleoPreSelected, setIsCannaleoPreSelected] = useState(false);
    
    // Map of placeholders to replace in thank you page bullets
    const [placeHoldersMap, setPlaceHoldersMap] = useState<Record<string, string>>({});
    
    const relocationString = useMemo(() => {

        if(isEPrescriptionOnly) {
            return `/${language}/account/my-orders/${hashID}/details#pharmacy-selection-container`
        } 
            
        return `/${language}/account/my-orders` 
    },
    [isEPrescriptionOnly,language,country])


    const btnTextTranslationKey = (isEPrescriptionOnly  && !isCannaleoPreSelected) ? "page/button_text_e_prescription" : "page/button_text";

    // const categoryMention = searchParams.get("cat");
    useEffect(() => {
        window.addEventListener("popstate", (e) => {
        e.preventDefault();
        window.location.replace(relocationString);
         })
    });

    useEffect(() => {

        if (thankyouHash && hashID){

            
            http.getFromServer(`thank_you/${country}/${hashID}/${thankyouHash}`, [])
            .then((response) => {
                
                    if (SessionStorageWrapper.getItem("last_thankyou_order") !== hashID){
                        MainTracker.superProperties(response.data.ConversionData.tracking)
                        MainTracker.track('custom', 'ViewThankyouPage', response.data.ConversionData);
                        SessionStorageWrapper.setItem("last_thankyou_order", hashID);
                    }

                    setIsCannaleoPreSelected(response.data.flow === ORDER_FLOWS.CANNALEO_PRE_SELECTED);
                    
                    setPlaceHoldersMap(response.data.placeHoldersMap);

                    if (response.data.isSelfPickup) {
                        setIsSelfPickUp(true);
                    } else if (response.data.isEPrescriptionOnly) {
                        setIsEPrescriptionOnly(true);
                    }
                })
                .catch((err) => {
                    
                    if (err.response?.data?.logLevel === "warn"){
                        window.clientLogger.warn("Thanyou Page get error", err)
                    }
                    else{
                        window.clientLogger.error("Thanyou Page get error", err)
                    }
                });
        }

    }, []);

    return (
                <>
                    <Trustedshops
                    isThankyouPage={true}
                    language={language}
                    />
                    <HeaderController language={language} showSteps={false} stage={"Treatment"} />
                    <div className="body-container">
                        <div className="thankyou-container">

                            <ThankyouMainTitleWidget
                                thankyouTitle={translator.translate(language, "thankyou", "page/title")}
                                thankyouSubTitle={translator.translate(language, "thankyou", "page/subtitle")}
                            />

                            <div className="thankyou-steps-container">
                                <p className="thankyou-steps-title">
                                    {translator.translate(language, "thankyou", "page/steps_title")}
                                </p>

                                <ThankyouStepsWidget placeHoldersMap={placeHoldersMap} isCannaleoPreSelected={isCannaleoPreSelected} isSelfPickUp={isSelfPickUp} isEPrescriptionOnly={isEPrescriptionOnly} isOrderOnlyPrescription={isOrderOnlyPrescription} isERecipe={isERecipe} language={language} />


                                <SubmitButton
                                    id={"submit-button"}
                                    language={language}
                                    placeholder={translator.translate(language, "thankyou", btnTextTranslationKey)}
                                    onSubmit={() => {
                                        window.location.replace(relocationString);
                                    }}
                                />

                            </div>
                        </div>
                        <div id="trustbadgeCustomCheckoutContainer"></div>
                    </div>
                </>
    )
}
