import React from "react";
import ShoppingCartIcon from "../../src/icons/Shopping-Bag-White.svg";

type Props = {
  shouldExpand: boolean;
  title: string;
  mainText: string;
};

const HoverCard: React.FC<Props> = ({ shouldExpand, title, mainText }) => {

  return (
    <div
      id="hover-card"
      className={`flex gap-3 lg:gap-5 absolute left-0 top-0 h-full bg-[#EDFCF0] transition-all duration-300 ease-in-out rounded-[8px] w-full ${shouldExpand ? "opacity-100 pr-4 lg:pr-8" : "opacity-0"
        } overflow-hidden`}
    >
      {/* Icon Container */}
      <div
        id="hover-card-icon-container"
        className="bg-[#19D845] h-full flex items-center p-4 rounded-l-xl rounded-r-md"
      >
        <img src={ShoppingCartIcon} alt="Shopping Cart Icon" />
      </div>

      {shouldExpand && (
        /* Content (texts) */
        <div id="hover-card-content-container" className={`flex flex-col justify-center relative `}>
          {/* Content Title */}
          <p
            id="hover-card-title"
            className={`italic text-xs text-[#616161] break-words`}
          >
            {title}
          </p>

          {/* Content Main Text */}
          <p
            id="hover-card-main-text"
            className={`text-base text-[#0D2C54] font-semibold whitespace-normal break-words`}
          >
            {mainText}
          </p>
        </div>
      )}

    </div>
  );
};

export default HoverCard;
