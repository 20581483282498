import React from 'react'
import { getPrice } from '../utils/translator'
import { clientConfig } from '../config'
import { useCartContext } from '../customHooks/useCart'
import { useSearchParams } from 'react-router-dom'
const CANNABIS_ID_TITLE = "Digitaler Cannabis-Ausweis";
type Props = {
  productItem: CartProductView
  className?: string
}

const CartViewProductRow: React.FC<Props> = ({ productItem, className }) => {

  const prescriptionOnlyImage = clientConfig.IMAGES_URL + clientConfig.PRESCRIPTION_IMAGE;
  const { getCart } = useCartContext();
  const cart = getCart();
  const [searchParams] = useSearchParams();


  return (
    <div id='cart-view-product-row' style={{ borderBottom: "1px solid #DBE1EB40" }} className={` flex gap-4 items-center justify-between ${className}`}>

      {/* Image */}
      <img src={cart.only_prescription && productItem.title !== CANNABIS_ID_TITLE ? prescriptionOnlyImage : productItem.imageSrc} alt="Product" style={{ width: "75px", height: "75px", objectFit: "cover" }} />

      {/* Title & Quantity */}
      <div id='cart-view-product-row-details' className='flex flex-col gap-1'>
        <p id='cart-view-product-row-title' className='font-roboto font-medium text-md text-[#0D2C54] break-words !leading-[15px]'> {productItem.title} </p>
        <p id='cart-view-product-row-quantity' className='text-[#656D79] text-xs font-roboto '> Quantity: {productItem.quantityStr} </p>
      </div>

      {/* Price */}
      {
        searchParams.get("epi") || cart.only_prescription ? null :
          <p id='cart-view-product-row-price' className='font-roboto font-medium text-md text-[#0D2C54]'>
            {getPrice(!cart.only_prescription && productItem.title === CANNABIS_ID_TITLE ? 0 : productItem.item_price, cart.locale_id)}
          </p>
      }

    </div>
  )
}

export default CartViewProductRow

